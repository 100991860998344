// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-myworks-js": () => import("./../../../src/pages/myworks.js" /* webpackChunkName: "component---src-pages-myworks-js" */),
  "component---src-pages-resume-js": () => import("./../../../src/pages/resume.js" /* webpackChunkName: "component---src-pages-resume-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-work-details-opensource-paypal-butterfly-js": () => import("./../../../src/pages/work-details/opensource/paypal-butterfly.js" /* webpackChunkName: "component---src-pages-work-details-opensource-paypal-butterfly-js" */),
  "component---src-pages-work-details-opensource-telescope-js": () => import("./../../../src/pages/work-details/opensource/telescope.js" /* webpackChunkName: "component---src-pages-work-details-opensource-telescope-js" */),
  "component---src-pages-work-details-opensource-thaw-js": () => import("./../../../src/pages/work-details/opensource/thaw.js" /* webpackChunkName: "component---src-pages-work-details-opensource-thaw-js" */),
  "component---src-pages-work-details-projects-aid-management-app-js": () => import("./../../../src/pages/work-details/projects/aid-management-app.js" /* webpackChunkName: "component---src-pages-work-details-projects-aid-management-app-js" */),
  "component---src-pages-work-details-projects-amazing-shop-js": () => import("./../../../src/pages/work-details/projects/amazing-shop.js" /* webpackChunkName: "component---src-pages-work-details-projects-amazing-shop-js" */),
  "component---src-pages-work-details-projects-blink-js": () => import("./../../../src/pages/work-details/projects/blink.js" /* webpackChunkName: "component---src-pages-work-details-projects-blink-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

